import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import bannerimg from "../assets/images/banner-inner-page.jpg";
import bannerimg1 from "../assets/images/inner-banner-shape2.png";
import bannerimg2 from "../assets/images/inner-banner-shape1.png";
import { FaAddressBook, FaAnglesRight, FaEnvelope, FaLocationCrosshairs } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import "../assets/css/contact.css";
import getInTouch from "../assets/images/icon/section-title.png";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    c_name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const validate = () => {
    let errors = {};
    
    if (!formData.c_name) errors.c_name = 'Name is required';
    
    if (!formData.email) {
      errors.email = 'Email is required';
    } else {
      const emailRegex = /^\w+([-+.']\w+)*@[A-Za-z\d]+\.com$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = 'Please enter a valid email, i.e., abc@gmail.com';
      }
    }
    
  
    if (!formData.phone) {
      errors.phone = 'Phone is required';
    } else {
      const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
      const allSameDigits = /^(.)\1{9}$/;
      if (!phoneRegex.test(formData.phone)) {
        errors.phone = 'Phone number must be exactly 10 digits and should not start with 0 or 1';
      } else if (allSameDigits.test(formData.phone)) {
        errors.phone = 'Phone number should not have all the same digits';
      }
    }
    
    if (!formData.subject) errors.subject = 'Subject is required';
    if (!formData.message) errors.message = 'Message is required';
    
    return errors;
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log('Form data:', formData);
      setDisable(true);

      fetch('https://formspree.io/f/mjkbbwjp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.ok) {
            toast.success('Form submitted successfully');
            setFormData({
              c_name: '',
              email: '',
              phone: '',
              subject: '',
              message: '',
            });
            setDisable(false);
          } else {
            toast.error('Failed to submit form');
            setDisable(false);
          }
        })
        .catch((error) => {
          toast.error('Error submitting form');
          setDisable(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div>
        <Header />
      </div>
      <section
        className="banner__inner-page bg-image pt-180 pb-180 bg-image"
        data-background={bannerimg}
      >
        <div className="shape2">
          <img src={bannerimg1} alt="shape" />
        </div>
        <div className="shape1">
          <img src={bannerimg2} alt="shape" />
        </div>
        {/* <div className="shape3">
          <img className="sway__animationX" src={bannerimg3} alt="shape" />
        </div> */}
        <div className="container">
          <h2>
            Contact Us
          </h2>
          <div className="breadcrumb-list">
            <a href="/">Home</a>
            <span>
              &nbsp;&nbsp;&nbsp;
              <FaAnglesRight /> &nbsp;&nbsp;&nbsp; Contact Us
            </span>
          </div>
        </div>
      </section>

      {/* contact page */}

      <section className="contact-area pt-120 pb-120">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-6 col-lg-7">
              <div className="animated">
                <div className="contact__left-item">
                  <h2 className="text-white mb-30"><FaAddressBook></FaAddressBook><>  </>Contact Information</h2>
                  <p className="text-white">
                    Feel free to ask whenever you need for any support or any queries.
                  </p>
                  <ul className="mt-40 mb-40">
                    <li>
                      <i>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {/* Phone icon SVG */}
                        </svg>
                      </i>
                      <div>
                        <span className="text-white"><FaPhone></FaPhone> <>  </>Call Us 24/7</span>
                        <h3 className="mt-1">
                          <a className="text-white" href="tel:+91 8637871971">
                            +91 8637871971
                          </a>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <i>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {/* Email icon SVG */}
                        </svg>
                      </i>
                      <div>
                        <span className="text-white"><FaEnvelope></FaEnvelope><>  </>Make a Quote</span>
                        <h3 className="mt-1">
                          <a className="text-white" href="mailto:admin@agcinfosystem.com">
                            admin@agcinfosystem.com
                          </a>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <i>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {/* Location icon SVG */}
                        </svg>
                      </i>
                      <div>
                        <span className="text-white"><FaLocationCrosshairs></FaLocationCrosshairs><>  </>Location</span>
                        <h3 className="mt-1">
                          <a className="text-white" href="tel:+2085550112">
                            Kolkata, West Bengal
                          </a>
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <h4 className="text-white mb-20">Follow Social:</h4>
                  <div className="social">
                    <a href="https://www.facebook.com/agcipvt?mibextid=ZbWKwL">
                      <FaFacebookF />
                    </a>
                    <a href="https://www.linkedin.com/company/agc-infosystem-private-limited/">
                      <FaLinkedinIn />
                    </a>
                    {/* <a href="#0">
                    <FaInstagram />
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="contact__right-item">
                <div className="section-header mb-20">
                  <h5 className="wow pb-2">
                    <img className="me-1" src={getInTouch} alt="icon" />
                    GET IN TOUCH
                  </h5>
                  <h2>
                    Ready to Get Started?
                  </h2>
                </div>
                <div className="contact__form">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Your Name<span className="red_star">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            name="c_name"
                            placeholder="Enter your name"
                            autoComplete="off"
                            value={formData.c_name}
                            onChange={handleChange}
                          />
                          {errors.c_name && <span className="text-danger">{errors.c_name}</span>}
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Your Email<span className="red_star">*</span></Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            autoComplete="off"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {errors.email && <span className="text-danger">{errors.email}</span>}
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Your Contact<span className="red_star">*</span></Form.Label>
                          <Form.Control
                            type="number"
                            name="phone"
                            className="phone"
                            placeholder="Enter your phone no."
                            autoComplete="off"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && <span className="text-danger">{errors.phone}</span>}
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Your Subject<span className="red_star">*</span></Form.Label>
                          <Form.Control
                            as="select"
                            name="subject"
                            placeholder="--Select subject--"
                            value={formData.subject}
                            onChange={handleChange}
                          >
                            <option value="">--Select--</option>
                            <option>Web Development</option>
                            <option>Graphic Design</option>
                            <option>UI/UX Design</option>
                            <option>App Development</option>
                            <option>SEO Optimization</option>
                            <option>Digital Marketing</option>
                          </Form.Control>
                          {errors.subject && <span className="text-danger">{errors.subject}</span>}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="text-area">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Write Message<span className="red_star">*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                        {errors.message && <span className="text-danger">{errors.message}</span>}
                      </Form.Group>
                    </div>
                    <div className="btn-two">
                      <button
                        type="submit"
                        className="btn"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* map */}
      <section>
        <div className="map mb-100">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722101.812001147!2d85.21010277073388!3d24.351193068474604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fa16ff1397e887%3A0x71543a3dc3e7a20a!2sWest%20Bengal!5e0!3m2!1sen!2sin!4v1716664145150!5m2!1sen!2sin"
            width='100%'
            height='350'
          ></iframe>
        </div>
      </section>

      {/* end */}

      <div>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
