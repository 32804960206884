import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import "../assets/css/home.css";
import sectiontitle from "../assets/images/section-title.png";
import caseimage1 from "../assets/images/case-image1.jpg";
import caseimage2 from "../assets/images/case-image2.jpg";
import caseimage3 from "../assets/images/case-image3.jpg";
import caseimage4 from "../assets/images/case-image4.jpg";

SwiperCore.use([Pagination, Autoplay]);

const Wedeliver = () => {
  return (
    <section className="case-area py-5">
      <div className="container">
        <div className="d-flex flex-wrap gap-4 align-items-center justify-content-between mb-60">
          <div className="section-header">
            <h5
              className="wow fadeInLeft"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <img className="me-1" src={sectiontitle} alt="icon" />
              FROM OUR CASE studies
            </h5>
            <h2
              className="wow fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              We Delivered Best Solution
            </h2>
          </div>
        </div>
      </div>
      <Swiper
        className="swiper case__slider"
        spaceBetween={30}
        slidesPerView={4}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage1} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Solution</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  IT Management
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Second image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage2} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Technology</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Platform Integration
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Third image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage3} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Solution</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Web Development
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Fourth image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage4} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Security</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Network Security
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        {/* Repeat the SwiperSlides to have multiple sets of images */}
        <SwiperSlide>
          {/* First image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage1} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Solution</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  IT Management
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Second image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage2} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Technology</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Platform Integration
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Third image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage3} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Solution</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Web Development
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {/* Fourth image */}
          <div className="case__item">
            <div className="image case__image">
              <img src={caseimage4} alt="" />
            </div>
            <div className="case__content">
              <span className="primary-color sm-font">Security</span>
              <h3>
                <a
                  href="case-details.html"
                  className="text-white primary-hover"
                >
                  Network Security
                </a>
              </h3>
            </div>
            <a href="case-details.html" className="case__btn">
              <i className="fa-regular fa-arrow-right" />
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Wedeliver;
