import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import '../assets/css/customButton.css';

const CustomButton = ({ text }) => {

  return (
    <button
      className="fw-bold m-t-15 waves-effect global-button"
    >
      {text}
      <span className="btn-arrow"><FaArrowRightLong /></span>
    </button>
  );
};

export default CustomButton;
