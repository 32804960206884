import React from "react";
import "../assets/css/footer.css";
import { FaAnglesRight, FaEnvelope } from "react-icons/fa6";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPhoneAlt,
} from "react-icons/fa";
import footerRegularLeft from "../assets/images/footer-regular-left.png";
import footerSolidLeft from "../assets/images/footer-solid-left.png";
import footerRegularRight from "../assets/images/footer-regular-right.png";
import footerSolidRight from "../assets/images/footer-solid-right.png";
import logo from "../assets/images/logo.png";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer-area secondary-bg">
      <div
        className="footer__shape-regular-left wow slideInLeft"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <img src={footerRegularLeft} alt="" />
      </div>
      <div
        className="footer__shape-solid-left wow slideInLeft"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <img src={footerSolidLeft} alt="" />
      </div>
      <div
        className="footer__shape-solid-right wow slideInRight"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <img src={footerRegularRight} alt="" />
      </div>
      <div
        className="footer__shape-regular-right wow slideInRight"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <img src={footerSolidRight} alt="" />
      </div>
      {/* <div className="footer__shadow-shape">
        <img src={footerShadowShape} alt="" />
      </div> */}
      <div className="container">
        <div className="footer__wrp ">
          <div
            className="footer__item item-big pb-5"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <a href="/" className="logo">
              <div className="footer_logo">
                <img src={logo} alt="" />
              </div>
            </a>
            <p>
              {/* <TbInfoCircle /> Agile Solutions for Global Connectivity */}
            </p>
            <div className="social-icon">
              <a href="https://www.facebook.com/agcipvt?mibextid=ZbWKwL">
                <FaFacebookF />
              </a>
              <a href="https://www.linkedin.com/company/agc-infosystem-private-limited/">
                <FaLinkedinIn />
              </a>
              {/* <a href="#0">
                <FaTwitter />
              </a> */}

            </div>
          </div>

          <div
            className="footer__item item-big wow fadeInUp mb-4"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <h3 className="footer-title">Software Solution</h3>
            <ul>
              <li>
              <a href="/services">
                  <FaAnglesRight /> Software Management
                </a>
              </li>
              <li>
              <a href="/services">
                  <FaAnglesRight /> SEO Optimization
                </a>
              </li>
              <li>
              <a href="/services">
                  <FaAnglesRight /> Web Development
                </a>
              </li>
            </ul>
          </div>

          <div
            className="footer__item item-big wow fadeInUp pb-5"
            data-wow-delay="400ms"
            data-wow-duration="1500ms"
          >
            <h3 className="footer-title">Quick Link</h3>
            <ul>
              <li>
                <a href="/about">
                  <FaAnglesRight /> About AGC
                </a>
              </li>
              <li>
                <a href="/contact">
                  {/* <FaAnglesRight /> Our Services */}
                  <FaAnglesRight /> Contact Us
                </a>
              </li>

              {/* <li>
                <span className="footspan" >
                  <FaAnglesRight /> Our Projects
                </span>
              </li>
              <li>
                <span className="footspan" >
                  <FaAnglesRight /> Our Team
                </span>
              </li> */}
            </ul>
          </div>

          <div
            className="footer__item item-big wow fadeInUp"
            data-wow-delay="600ms"
            data-wow-duration="1500ms"
          >
            <h3 className="footer-title">Contact Us</h3>

            <ul className="footer-contact">
              <li>
                <FaPhoneAlt className="icon" />
                <div className="info">
                  <h5>Phone Call:</h5>
                  <p> +91 8637871971 </p>
                </div>

              </li>

              <li>
                <MdEmail className="icon" />
                <div className="info">
                  <h5>Email:</h5>
                  <a className="text-white" href="mailto:admin@agcinfosystem.com">

                    admin@agcinfosystem.com
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container">
          <div className="text-center">
            <p
              className="wow fadeInDown"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              &copy; 2024 <a href="/" className="text-decoration-underline">AGC Infosystem</a>  All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
