import React from "react";
import "../assets/css/home.css";
import sectiontitle from "../assets/images/section-title.png";
import processarry from "../assets/images/process-arry.png";
import processimg1 from "../assets/images/process-image1.png";
import processimg2 from "../assets/images/process-image2.png";
import processimg3 from "../assets/images/process-image3.png";

const DevelopmentProcess = () => {
  return (
    <section className="process-area pt-120">
      <div className="container">
        <div className="section-header text-center mb-60">
          <h5
            className="wow fadeInUp"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <img className="me-1" src={sectiontitle} alt="icon" />
            Work Process
          </h5>
          <h2
            className="wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            Our Development Process
          </h2>
        </div>
        <div className="row g-4" data-aos="zoom-in-up">
          <div className="col-lg-4" >
            <div className="process__item mb-100" >
              <div className="process-arry bobble__animation">
                <img src={processarry} alt="arry-icon" />
              </div>
              <div className="process__image">
                <img src={processimg1} alt="" />
                <span className="process-number">1</span>
              </div>
              <div className="process__content">
                <h4 className="mt-25 mb-10">Define Requirements</h4>
                <p>
               We analyse the client’s requirements and then gather minute details from them. And what is their purpose in creating the software </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4">
            <div className="process__item mb-100">
              <div className="process-arry bobble__animation">
                <img src={processarry} alt="arry-icon" />
              </div>
              <div className="process__image">
                <img src={processimg2} alt="" />
                <span className="process-number">2</span>
              </div>
              <div className="process__content">
                <h4 className="mt-25 mb-10">Design &amp; Prototyping</h4>
                <p>
                We create a demo website layout or prototypes that allow users to interact with the website's functionality (even if it's not fully coded)
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4">
            <div className="process__item">
              <div className="process__image">
                <img src={processimg3} alt="" />
                <span className="process-number">3</span>
              </div>
              <div className="process__content">
                <h4 className="mt-25 mb-10">Finial Solution</h4>
                <p>
                From initial concept to launch, we work closely with our clients, building trust and a strong partnership
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentProcess;
