import React from "react";
import "../assets/css/header.css";
import logo from "../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
const Header = () => {
  return (
    <>
      <header className="header-area">
        <div className="container header__container">
          <div className="header__main text-white fw-bold">
            <div className="logo">
            <a href="/" >
              <img src={logo} alt="" />
              </a>
            </div>
            <Navbar expand="lg" className="mb-3">
              <Container fluid>
                <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
                <Navbar.Offcanvas
                  id="offcanvasNavbar-expand-lg"
                  aria-labelledby="offcanvasNavbarLabel-expand-lg"
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                      <b>AGC Infosystem</b>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu">
                      <Nav.Link href="/" className="me-3">
                        Home
                      </Nav.Link>
                      <Nav.Link href="/about" className="me-3">
                        About
                      </Nav.Link>
                      <Nav.Link href="/services" className="me-3">
                        Services
                      </Nav.Link>
                      <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;