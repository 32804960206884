import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import bannerLine from "../assets/images/banner-line.png";
import bannerRegularLeftShape from "../assets/images/banner-regular-left-shape.png";
import bannerSolidLeftShape from "../assets/images/banner-solid-left-shape.png";
import bannerShapeRightLine from "../assets/images/banner-shape-right-line.png";
import bannerShapeRight from "../assets/images/banner-shape-right.png";
import bannerRightLine1 from "../assets/images/banner-right-line1.png";
import bannerRightLine2 from "../assets/images/banner-right-line2.png";
import bannerRightLine3 from "../assets/images/banner-right-line3.png";
import bannerRightLine4 from "../assets/images/banner-right-line4.png";
import bannerImage from "../assets/images/banner-image.jpg";
import bannerImage2 from "../assets/images/banner-image2.jpg";
import bannerImage3 from "../assets/images/banner-image3.jpg";
import sectionTitle from "../assets/images/section-title.png";
import icon1 from "../assets/images/about-icon1.png";
import icon2 from "../assets/images/about-icon2.png";
import about1 from "../assets/images/about-image1.jpg";
import about2 from "../assets/images/about-two-image1.jpg";
import aboutCircle from "../assets/images/about-circle.png";
// import contact from "../assets/images/contact-us.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import ItService from "./ItService";
import "../assets/css/home.css";
import Wedeliver from "./Wedeliver";
import DevelopmentProcess from "./DevelopmentProcess";
import CustomButton from "../components/CustomButton";
import CustomFrom from "../components/CustomFrom";
import AOS from "aos";
import "aos/dist/aos.css";
import Technology from "./Technology";
import { Col, Container, Row } from "react-bootstrap";
SwiperCore.use([Pagination, Autoplay]);

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="home-page">
      {/* header sec  */}
      <div>
        <Header />
      </div>

      {/* header main carousel  */}
      <section className="banner-area">
        {/* <div className="banner__line">
          <img src={bannerLine} alt="" />
        </div> */}

        <Swiper
          className="swiper banner__slider"
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
         <SwiperSlide>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".3s"
              className="banner__shape-left2"
            >
              <img src={bannerRegularLeftShape} alt="" />
            </div>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".9s"
              className="banner__shape-left1"
            >
              <img src={bannerSolidLeftShape} alt="" />
            </div>
            <div className="banner__shape-left3 wow slideInLeft"></div>
            <div
              className="banner__shape-right2"
              data-animation="slideInRight"
              data-duration="3s"
              data-delay=".3s"
            >
              <img src={bannerShapeRightLine} alt="" />
            </div>
            <div
              className="banner__shape-right1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerShapeRight} alt="" />
            </div>
            <div
              className="banner__right-line1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".9s"
            >
              <img src={bannerRightLine1} alt="" />
            </div>
            <div
              className="banner__right-line2"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".7s"
            >
              <img src={bannerRightLine2} alt="" />
            </div>
            <div
              className="banner__right-line3"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".5s"
            >
              <img src={bannerRightLine3} alt="" />
            </div>
            <div
              className="banner__right-line4"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerRightLine4} alt="" />
            </div>
            <img className="slide-bg" src={bannerImage} alt="" />
            <div className="container">
              <div className="banner__content">
                <h4
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".3s"
                  className="text-white mb-20"
                >
                  <svg
                    className="me-1"
                    width="40"
                    height="16"
                    viewBox="0 0 40 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.500183"
                      width="25.6667"
                      height="15"
                      rx="7.5"
                      stroke="white"
                    />
                    <rect
                      x="13.3334"
                      y="0.000183105"
                      width="26.6667"
                      height="16"
                      rx="8"
                      fill="white"
                    />
                  </svg>
                  Best it SOULTION Provider
                </h4>
                <h1
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".5s"
                  className="text-white"
                  data-aos="fade-right"
                >
       We solidify  your ideas in the software field.
                </h1>
                <p
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".7s"
                  className="mt-20"
                >
                  {/* Consectetur adipiscing elit aenean scelerisque at augue vitae
                  consequat */}
                  <br />
                  {/* quisque eget congue velit in cursus leo sed sodales est eget
                  turpis. */}
                </p>
                <a
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".9s"
                  // href="/services"
                  className="btn-one mt-60"
                >
                  {/* <CustomButton text="Explore More" /> */}
                </a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".3s"
              className="banner__shape-left2"
            >
              <img src={bannerRegularLeftShape} alt="" />
            </div>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".9s"
              className="banner__shape-left1"
            >
              <img src={bannerSolidLeftShape} alt="" />
            </div>
            <div className="banner__shape-left3 wow slideInLeft"></div>
            <div
              className="banner__shape-right2"
              data-animation="slideInRight"
              data-duration="3s"
              data-delay=".3s"
            >
              <img src={bannerShapeRightLine} alt="" />
            </div>
            <div
              className="banner__shape-right1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerShapeRight} alt="" />
            </div>
            <div
              className="banner__right-line1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".9s"
            >
              <img src={bannerRightLine1} alt="" />
            </div>
            <div
              className="banner__right-line2"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".7s"
            >
              <img src={bannerRightLine2} alt="" />
            </div>
            <div
              className="banner__right-line3"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".5s"
            >
              <img src={bannerRightLine3} alt="" />
            </div>
            <div
              className="banner__right-line4"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerRightLine4} alt="" />
            </div>
            <img className="slide-bg" src={bannerImage2} alt="" />
            <div className="container">
              <div className="banner__content">
                <h4
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".3s"
                  className="text-white mb-20"
                >
                  <svg
                    className="me-1"
                    width="40"
                    height="16"
                    viewBox="0 0 40 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.500183"
                      width="25.6667"
                      height="15"
                      rx="7.5"
                      stroke="white"
                    />
                    <rect
                      x="13.3334"
                      y="0.000183105"
                      width="26.6667"
                      height="16"
                      rx="8"
                      fill="white"
                    />
                  </svg>
                  Best it SOULTION Provider
                </h4>
                <h1
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".5s"
                  className="text-white"
                  data-aos="fade-right"
                >
     You've Got a Business, We've Brilliant Minds.
                </h1>
                <p
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".7s"
                  className="mt-20"
                >
                  {/* Consectetur adipiscing elit aenean scelerisque at augue vitae
                  consequat */}
                  <br />
                  {/* quisque eget congue velit in cursus leo sed sodales est eget
                  turpis. */}
                </p>
                <a
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".9s"
                  href="/services"
                  className="btn-one mt-60"
                >
                  {/* <CustomButton text="Explore More" /> */}
                </a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".3s"
              className="banner__shape-left2"
            >
              <img src={bannerRegularLeftShape} alt="" />
            </div>
            <div
              data-animation="slideInLeft"
              data-duration="2s"
              data-delay=".9s"
              className="banner__shape-left1"
            >
              <img src={bannerSolidLeftShape} alt="" />
            </div>
            <div className="banner__shape-left3 wow slideInLeft"></div>
            <div
              className="banner__shape-right2"
              data-animation="slideInRight"
              data-duration="3s"
              data-delay=".3s"
            >
              <img src={bannerShapeRightLine} alt="" />
            </div>
            <div
              className="banner__shape-right1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerShapeRight} alt="" />
            </div>
            <div
              className="banner__right-line1"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".9s"
            >
              <img src={bannerRightLine1} alt="" />
            </div>
            <div
              className="banner__right-line2"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".7s"
            >
              <img src={bannerRightLine2} alt="" />
            </div>
            <div
              className="banner__right-line3"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".5s"
            >
              <img src={bannerRightLine3} alt="" />
            </div>
            <div
              className="banner__right-line4"
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".3s"
            >
              <img src={bannerRightLine4} alt="" />
            </div>
            <img className="slide-bg" src={bannerImage3} alt="" />
            <div className="container">
              <div className="banner__content">
                <h4
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".3s"
                  className="text-white mb-20"
                >
                  <svg
                    className="me-1"
                    width="40"
                    height="16"
                    viewBox="0 0 40 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.500183"
                      width="25.6667"
                      height="15"
                      rx="7.5"
                      stroke="white"
                    />
                    <rect
                      x="13.3334"
                      y="0.000183105"
                      width="26.6667"
                      height="16"
                      rx="8"
                      fill="white"
                    />
                  </svg>
                  Best it SOULTION Provider
                </h4>
                <h1
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".5s"
                  className="text-white"
                  data-aos="fade-right"
                >
    Concepts sculpted, collaboration thrives.
                </h1>
                <p
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".7s"
                  className="mt-20"
                >
                  {/* Consectetur adipiscing elit aenean scelerisque at augue vitae
                  consequat */}
                  <br />
                  {/* quisque eget congue velit in cursus leo sed sodales est eget
                  turpis. */}
                </p>
                <a
                  data-animation="slideInRight"
                  data-duration="2s"
                  data-delay=".9s"
                  href="/services"
                  className="btn-one mt-60"
                >
                  {/* <CustomButton text="Explore More" /> */}
                </a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="banner__dot-wrp">
          <div className="dot-light banner__dot"></div>
        </div>
      </section>

      {/* service section  */}
      <div data-aos="zoom-in">
        <ItService />
      </div>

      {/* about us sec  */}
      <section className="about-area sub-bg pt-120 pb-120">
        <div
          className="about__shape wow slideInLeft"
          data-wow-delay="400ms"
          data-wow-duration="1500ms"
        >
          {/* <img src="assets/images/shape/about-line.png" alt="shape"> */}
        </div>
        <div className="container">
          <div className="row g-4">
            <div
              className="col-xl-6 wow fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about__left-item">
                <div className="image big-image">
                  <img src={about2} alt="" className="me-1" />
                </div>
                <div className="image sm-image">
                  <img src={about1} alt="" className="me-1" />
                </div>

                <div className="circle-shape">
                  {/* <img src="assets/images/shape/about-circle.png" alt="shape"> */}
                  <img src={aboutCircle} alt="" className="me-1" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="section-header mb-40">
                <h5
                  className="wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <img src={sectionTitle} alt="" className="me-1" />
                  ABOUT AGC
                </h5>
                <h2
                  className="wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
          We turn your ideas into reality with our expert solutions.
                </h2>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                >
                 At AGC Infotech, we set our goal of becoming a frontrunner in this ever evolving IT discipline.
With a team of specialists in software development, digital marketing, and graphics design, we offer a powerful toolbox to optimize your operations and propel your success.
                </p>
              </div>
              <div
                className="row g-4 wow fadeInDown"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="col-md-6">
                  <div className="about__right-item">
                    <div className="icon">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="content">
                      <h4 className="mb-1">Best Services</h4>
                      <p>Our expertise is in the software development sector</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about__right-item">
                    <div className="icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="content">
                      <h4 className="mb-1">24/7 Customer Support</h4>
                      <p> Feel free to ask whenever you need to</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="about__info mt-50 wow fadeInDown"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                 <a
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".9s"
              href="/about"
              className="mt-60"
            >
                {/* <CustomButton text="Explore More" /> */}
                </a>
                <div className="d-flex gap-2 align-items-center"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* case study sec  */}
      <div data-aos="zoom-in-up">
        <Technology />
      </div>

      {/* development process sec  */}
      <DevelopmentProcess />

      {/* from section  */}
      <div
        className="contact-container sub-bg"

      >
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6} className="p-0">
              <div className="image-contact-container">
                {/* <img src={contact} alt="Contact" /> */}
                <h5 className="text-white" data-aos="zoom-in-up">
                  <span className="pe-1">
                    <svg
                      width={28}
                      height={12}
                      viewBox="0 0 28 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.75"
                        y="0.75"
                        width="18.5"
                        height="10.5"
                        rx="5.25"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <rect x={8} width={20} height={12} rx={6} fill="white" />
                    </svg>
                  </span>
                  TALK TO US
                </h5>
                <h2 className="text-white" data-aos="zoom-in-up">How May We Help You!</h2>
              </div>
            </Col>

            <Col xs={12} md={12} lg={6} className="p-0">
              <div className="form-contact-container">
                <CustomFrom />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer sec  */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
