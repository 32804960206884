import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { VscTerminalCmd } from "react-icons/vsc";
import "../assets/css/services.css";
import "../assets/css/style.css";
import { MdOutlineAppSettingsAlt } from "react-icons/md";
import { FaAnglesRight } from "react-icons/fa6";
import AOS from "aos";
import "../assets/css/customfrom.css";
import { TbSettingsSearch } from "react-icons/tb";
import { AiOutlineBgColors } from "react-icons/ai";
import { LuWallpaper } from "react-icons/lu";
import { AiOutlineStock } from "react-icons/ai";
import "aos/dist/aos.css";
import bannerimg from "../assets/images/banner-inner-page.jpg";
import bannerimg1 from "../assets/images/inner-banner-shape2.png";
import bannerimg2 from "../assets/images/inner-banner-shape1.png";
// import sectiontitle from "../assets/images/section-title.png";
// import CustomButton from "../components/CustomButton";
// import { Container } from "react-bootstrap";
const ServiceItem = ({ iconSrc, title, description }) => {
  return (
    <div
      className="col-lg-4 col-md-6 wow bounceInUp"
      data-wow-delay="00ms"
      data-wow-duration="1000ms"
    >
      <div className="service__item">
        <div className="service-shape"></div>
        <div className="service__icon">{iconSrc}</div>
        <h4 className="service_card_title">
          {title}
        </h4>
        <p className="service_card_title">{description}</p>
      </div>
    </div>
  );
};

const ServiceArea = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* header sec  */}
      <div>
        <Header />
      </div>
        <section className="service__banner bg-image pt-180 pb-180 bg-image"
          data-background={bannerimg}
        >
          <div className="shape2">
            <img src={bannerimg1} alt="shape" />
          </div>
          <div className="shape1" >
            <img src={bannerimg2} alt="shape" />
          </div>
          <div className="container">
            <h2>
              Our Services
            </h2>
            <div className="breadcrumb-list">
              <a href="/">Home</a>
              <span>
                &nbsp;&nbsp;&nbsp;
                <FaAnglesRight /> &nbsp;&nbsp;&nbsp; Our Services
              </span>
            </div>
          </div>
        </section>

      {/* <img className="" src={bannerImage} alt="" /> */}

      <section className="service-area pt-120 pb-120">
        <div className="container" data-aos="fade-up">
          <div className="row g-4">

            <ServiceItem
              iconSrc={<TbSettingsSearch color="white" size={30} />}
              title="SEO Optimization"
              description="We provide the best SEO optimization necessary for helping a page rank on Google or Bing-like search platforms. Getting organic views helps create content for your website. You have a higher probability of being found and chosen by users."
            />
            <ServiceItem
              iconSrc={<MdOutlineAppSettingsAlt color="white" size={30} />}
              title="App Development"
              description="Building mobile apps for smartphones, tablets, and assistants requires specialized tools and expertise across different platforms, like Android and iOS.We provide the best apps for the devices you carry in your pocket. "
            />
            <ServiceItem
              iconSrc={<VscTerminalCmd iFillFire color="white" size={30} />}
              title="Web Development"
              description="Unleash the next-level web presence you deserve. Our super-fast and user-friendly development tools empower anyone to build a winning website—to take control and dominate the digital era. "
            />
            <ServiceItem
              iconSrc={<AiOutlineBgColors color="white" size={30} />}
              title="Graphic Design"
              description="Our team creates stunning graphic and logo designs using industry-leading tools like Photoshop, Illustrator, and Canva.
              We excel at translating client ideas into impactful and realistic visuals."
            />
            <ServiceItem
              iconSrc={<LuWallpaper color="white" size={30} />}
              title="UI/UX Design"
              description="A feeling board or an exhausting software interface? Come to us!! We build the best UI/UX design that boosts engagement, conversions, and brand perception for tech companies. Let's create something that shines!"
            />
            <ServiceItem
              iconSrc={< AiOutlineStock color="white" size={30} />}
              title="Digital Marketing"
              description="Feeling invisible or outnumbered online? We can assist! We help businesses like yours reach more customers with targeted digital marketing campaigns. Our data-oriented ad campaigns conquer Facebook, Google, Instagram and more. "
            />
          </div>
        </div>
      </section>

      {/* footer sec  */}
      <div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceArea;
