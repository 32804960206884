import React from "react";
import "../assets/css/home.css";
import sectiontitle from "../assets/images/section-title.png";
import serviceItem from "../assets/images/service-item-shape.png";
import serviceIcon1 from "../assets/images/service-icon1.png";
import serviceIcon2 from "../assets/images/service-icon2.png";
import serviceIcon3 from "../assets/images/service-icon3.png";
import CustomButton from "../components/CustomButton";
const ItService = () => {
  return (
    <>
      <section className="service-area py-5">
        <div className="container">
          <div className="d-flex flex-wrap gap-4 align-items-center justify-content-between mb-60">
            <div className="section-header">
              <h5
                className="wow fadeInLeft"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <img className="me-1" src={sectiontitle} alt="icon" />
                What We OFFER
              </h5>
              <h2
                className="wow fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                Excellent It Services
              </h2>
            </div>
            <a
              data-animation="slideInRight"
              data-duration="2s"
              data-delay=".9s"
              href="/services"
              className="mt-60"
            >
              <CustomButton text="View All Services" />
            </a>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow bounceInUp"
              data-wow-delay="00ms"
              data-wow-duration="1000ms"
            >
              <div className="service__item_home">
                <div className="service-shape">
                  <img src={serviceItem} alt="shape" />
                </div>
                <div className="service__icon_home">
                  <img src={serviceIcon1} alt="icon" />
                </div>
                <h4 className="hover-white">Software Development</h4>
                <p>
                  Software development is like building a bridge. It's a group effort that involves various steps, from brainstorming ideas to writing code, testing functionality, and finally deploying the finished product.
                </p>
              </div>

            </div>
            <div
              className="col-lg-4 col-md-6 wow bounceInUp"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <div className="service__item_home">
                <div className="service-shape">
                  <img src={serviceItem} alt="shape" />
                </div>
                <div className="service__icon_home">
                  <img src={serviceIcon2} alt="icon" />
                </div>
                <h4 className="hover-white">
                 
                    Digital marketing
                  
                </h4>
                <p>
                  Make a perpetual online presence in the digital world with our
                  digital marketing services Come to us; our experience can give
                  you exactly what you want. unlock your business potential.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow bounceInUp"
              data-wow-delay="400ms"
              data-wow-duration="1000ms"
            >
              <div className="service__item_home">
                <div className="service-shape">
                  <img src={serviceItem} alt="shape" />
                </div>
                <div className="service__icon_home">
                  <img src={serviceIcon3} alt="icon" />
                </div>
                <h4 className="hover-white">
                  
                    Graphics design
                 
                </h4>
                <p>
                  We are a full-service IT company specializing in creating stunning graphic designs for businesses of all sizes. Our team of experienced and creative designers can help you with a wide range of graphic design needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItService;
