import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomButton from "../src/components/CustomButton";
import CustomFrom from "../src/components/CustomFrom";
import ItService from "./pages/ItService";
import Wedeliver from "./pages/Wedeliver";
import DevelopmentProcess from "./pages/DevelopmentProcess";
import ServiceArea from "./pages/Service";
import Blogs from "./pages/Blogs";
import Technology from "./pages/Technology";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/Contact";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/itservice" element={<ItService />} />
        <Route path="/wedeliver" element={<Wedeliver />} />
        <Route path="/wedeliver" element={<Wedeliver />} />
        <Route path="/from" element={<CustomFrom />} />
        <Route path="/development" element={<DevelopmentProcess />} />
        <Route path="/services" element={<ServiceArea />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/tech" element={<Technology />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
