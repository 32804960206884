import React from "react";
import "../assets/css/customfrom.css";
import { useState } from 'react';
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomFrom = () => {
  const [formData, setFormData] = useState({
    c_name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const validate = () => {
    let errors = {};
    
    if (!formData.c_name) errors.c_name = 'Name is required';
    
    if (!formData.email) {
      errors.email = 'Email is required';
    } else {
      const emailRegex = /^\w+([-+.']\w+)*@[A-Za-z\d]+\.com$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = 'Please enter a valid email, i.e., abc@def.com';
      }
    }
  
    if (!formData.phone) {
      errors.phone = 'Phone is required';
    } else {
      const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
      const allSameDigits = /^(.)\1{9}$/;
      if (!phoneRegex.test(formData.phone)) {
        errors.phone = 'Phone number must be exactly 10 digits and should not start with 0 or 1';
      } else if (allSameDigits.test(formData.phone)) {
        errors.phone = 'Phone number should not have all the same digits';
      }
    }
    
    if (!formData.subject) errors.subject = 'Subject is required';
    if (!formData.message) errors.message = 'Message is required';
    
    return errors;
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log('Form data:', formData);
      setDisable(true);

      fetch('https://formspree.io/f/mjkbbwjp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.ok) {
            toast.success('Form submitted successfully');
            setFormData({
              c_name: '',
              email: '',
              phone: '',
              subject: '',
              message: '',
            });
            setDisable(false);
          } else {
            toast.error('Failed to submit form');
            setDisable(false);
          }
        })
        .catch((error) => {
          toast.error('Error submitting form');
          setDisable(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="talk-us__item">
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-sm-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Your Name<span className="red_star">*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="c_name"
                  placeholder="Enter your name"
                  autoComplete="off"
                  value={formData.c_name}
                  onChange={handleChange}
                  required
                />
                {errors.c_name && <span className="text-danger">{errors.c_name}</span>}
              </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Your Email<span className="red_star">*</span></Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <span className="text-danger">{errors.email}</span>}
              </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Your Subject<span className="red_star">*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="subject"
                  placeholder="--Select subject--"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                >
                  <option value="">--Select--</option>
                  <option>Web Development</option>
                  <option>Graphic Design</option>
                  <option>UI/UX Design</option>
                  <option>App Development</option>
                  <option>SEO Optimization</option>
                  <option>Digital Marketing</option>
                </Form.Control>
                {errors.subject && <span className="text-danger">{errors.subject}</span>}
              </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Your Contact<span className="red_star">*</span></Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Enter your phone no."
                  autoComplete="off"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  maxLength="10"
                  minLength="10"
                />
                {errors.phone && <span className="text-danger">{errors.phone}</span>}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Write Message<span className="red_star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>
          </div>
          <button
            type="submit"
            className="btn"
          >
            Send Message
          </button>
        </form>
      </div>
    </>
  );
};

export default CustomFrom;
