import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import bannerimg from "../assets/images/banner-inner-page.jpg";
import bannerimg1 from "../assets/images/inner-banner-shape2.png";
import bannerimg2 from "../assets/images/inner-banner-shape1.png";
import bannerimg3 from "../assets/images/inner-banner-shape3.png";
import { FaAnglesRight } from "react-icons/fa6";
import "../assets/css/about.css";
import sectionTitle from "../assets/images/section-title.png";
// import icon1 from "../assets/images/about-icon1.png";
// import icon2 from "../assets/images/about-icon2.png";
// import about1 from "../assets/images/about-image1.jpg";
// import about2 from "../assets/images/about-two-image1.jpg";
// import aboutCircle from "../assets/images/about-circle.png";
// import teamimg1 from "../assets/images/team-image1.jpg";
// import teamimg2 from "../assets/images/team-image2.jpg";
// import teamimg3 from "../assets/images/team-image3.jpg";
import vission from "../assets/images/about_us2.jpg";
import Vision1 from "../assets/images/icon/shared-vision.png";
import Mission from "../assets/images/icon/coworking.png";
import Moto from "../assets/images/icon/goal.png";

const AboutUs = () => {
  return (
    <>
      <div>
        <Header />
      </div>
      <section
        className="banner__inner-page bg-image pt-180 pb-180"
        data-background={bannerimg}
      >
        <div
          className="shape2 wow slideInLeft"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
        >
          <img src={bannerimg1} alt="shape" />
        </div>
        <div
          className="shape1 wow slideInLeft"
          data-wow-delay="200ms"
          data-wow-duration="1500ms"
        >
          <img src={bannerimg2} alt="shape" />
        </div>
        {/* <div
          className="shape3 wow slideInRight"
          data-wow-delay="200ms"
          data-wow-duration="1500ms"
        >
          <img className="sway__animationX" src={bannerimg3} alt="shape" />
        </div> */}
        <div className="container">
          <h2
            className="wow fadeInUp"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            About Us
          </h2>
          <div
            className="breadcrumb-list wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <a href="/">Home</a>
            <span>
              &nbsp;&nbsp;&nbsp;
              <FaAnglesRight /> &nbsp;&nbsp;&nbsp; About Us
            </span>
          </div>
        </div>
      </section>
      {/* stoty */}

      {/* about area */}
      <section className="about-area pt-100 pb-10">
        <div className="container">
          <div className="d-flex gap-2 align-items-center justify-content-center">
          <div className="row g-4">
            <div className="col-xl-5 col-lg-7 col-md-9">
              <div className="vision__left ">
                <div className="image big-image">
                  <img src={vission} alt="" className="me-1" />
                </div>
                </div>
              </div>
            <div className="col-xl-7 col-lg-5 col-md-10">
              <div className="section-header mb-40">
                <h5>
                  <img src={sectionTitle} alt="" className="me-1" />
                  About Us
                </h5>
                <h2>
                  We Strive to Offer Intelligent Business Solutions
                </h2>
                <p>
                  At AGC Infotech, we are passionate about helping businesses thrive in the digital age. Our team of experts in software development, digital marketing, and graphic design offers a comprehensive suite of services designed to streamline your operations and achieve your goals. We take a client-centric approach, becoming an extension of your team and working tirelessly to reduce your workload and maximize your impact. Let us show you how AGC Infotech can empower your business to reach new heights.
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>

      {/* items */}

      <section className="about-area1 mt-80 mb-0">
          <div className="container">
          <div className="d-flex flex-wrap gap-6 align-items-center justify-content-between mb-60">
          <div className="row g-4">
           <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="aboutpage_items">
                <img src={Vision1} alt="icon" />
                <h4>
                 
                    Our Vision
                  
                </h4>
                <p>
                As technology unifies us, our vision is to make you feel
safe and happy using our platform and to keep things professional. We're excited to build the future together.
                </p>
              </div>
            </div> 
            <div className="col-xl-4  col-lg-4 col-md-6">
              <div className="aboutpage_items">
                <img src={Mission} alt="icon" />
                <h4>
                  
                    Our Mission
                
                </h4>
                <p>
                Our mission is to take your innovative ideas and collaborate with our expertise’s 
value and deliver you a perfect helping platform in this digital world.
                </p>
              </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="aboutpage_items">
                <img src={Moto} alt="icon" />
                <h4>
                 
                    Our Motto
                 
                </h4>
                <p>
                Your trust is valuable to us. We give full assurance to you to give the best software 
platform according to your ideas.
                </p>
              </div>
              </div>
              </div>
              </div>
          </div>
      </section>
      



      {/* team members */}
      {/* <section className="team-area pt-120 pb-120">
        <div className="container">
          <div className="section-header text-center mb-60">
            <h5
              className="wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <img className="me-1" src={sectionTitle} alt="icon" />
              OUR team
            </h5>
            <h2
              className="wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              Our Leadership Team
            </h2>
          </div>
          <div className="row g-4">
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="team__item">
                <div className="image">
                  <img src={teamimg1} alt="image" />
                </div>
                <div className="team__content">
                  <h4>
                    <a className="text-white" href="team-details.html">
                      Kawser Ahmed
                    </a>
                  </h4>
                  <span className="text-white">Web Designer</span>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="team__item">
                <div className="image">
                  <img src={teamimg2} alt="image" />
                </div>
                <div className="team__content">
                  <h4>
                    <a className="text-white" href="team-details.html">
                      Karniz Fatema
                    </a>
                  </h4>
                  <span className="text-white">Customer Support</span>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="team__item">
                <div className="image">
                  <img src={teamimg3} alt="image" />
                </div>
                <div className="team__content">
                  <h4>
                    <a className="text-white" href="team-details.html">
                      Alex Pranto
                    </a>
                  </h4>
                  <span className="text-white">UI/UX Designer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
